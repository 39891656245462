<template>
  <div class="active-levels">
    <h3 class="heading-text">Levels</h3>
    <template v-if="fetching">
      <div class="overlay">
        <div class="d-flex">
          <b-spinner variant="danger"></b-spinner>
          <span class="ml-4">Fetching...</span>
        </div>
      </div>
    </template>
    <template v-else-if="!fetching && networkError === null">
      <div class="items">
        <b-col
          cols="6"
          class="d-grid-center"
          v-for="(levels, index) in allLevels"
          :key="index"
        >
          <SingleLevel
            :archived="false"
            :levelDetails="levels"
            :index="index"
            @editLevel="editLevel"
          />
        </b-col>
      </div>
    </template>
    <template v-else-if="!fetching && networkError !== null">
      <div class="d-flex flex-column align-items-center w-100">
        <div class="text-danger text-center display-4 p-4">API Error</div>
        <div class="text-danger text-center lead pb-4">
          Error while fetchting active notifications. Please refresh to try
          again.
        </div>
        <div class="text-danger text-center lead pb-4">
          {{ networkError }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import SingleLevel from "@/view/pages/bonus-levels/SingleLevel";
import { mapState, mapGetters } from "vuex";
import { GET_ALL_LEVELS } from "@/core/services/store/levels.module";
export default {
  components: {
    SingleLevel
  },
  methods: {
    ...mapGetters(["getAllLevels"]),
    update: function() {
      this.$store.dispatch(GET_ALL_LEVELS);
    },
    editLevel: function(index) {
      const level = this.allLevels[index];
      this.$emit("editLevel", level);
    }
  },
  computed: {
    ...mapState({
      allLevels: state => state.levels.allLevels,
      fetching: function(state) {
        return state.levels.levelsFetching;
      },
      networkError: function(state) {
        return state.levels.levelsError;
      }
    })
  },
  mounted() {
    this.$store.dispatch(GET_ALL_LEVELS);
  }
};
</script>
<style lang="scss" scoped>
.active-levels {
  h3.heading-text {
    font-size: 28px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    .d-grid-center {
      display: grid;
      place-items: center;
    }
  }
}
</style>
