<template>
  <div class="level-bonuses">
    <div class="row">
      <div class="top-row w-100">
        <div
          class="create-new-btn shadow-sm"
          v-if="activeTab === 0 || activeTab === 2"
          @click="newLevelBonusClick"
        >
          Create New
        </div>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Levels
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            Archive
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 2 ? 'active' : ''"
            @click="changeTab(2)"
          >
            Bonuses
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab active>
        <Levels ref="levelsRef" @editLevel="editLevel" />
      </b-tab>
      <b-tab>
        <ArchivedLevels />
      </b-tab>
      <b-tab>
        <Bonuses ref="bonusesRef" @editBonus="editBonus" />
      </b-tab>
    </b-tabs>
    <template v-if="region === 'PAKISTAN'">
      <NewLevel ref="newLevelRef" @addedNewLevel="addedNewLevel" />
    </template>
    <template v-else>
      <new-level-us ref="newLevelRef" @addedNewLevel="addedNewLevel" />
    </template>
    <template v-if="levelToEdit !== null">
      <EditLevel
        ref="editLevelRef"
        @editedLevel="editedLevel"
        :levelToEdit="levelToEdit"
        @close="closeEditLevel"
      />
    </template>
    <template v-if="bonusToEdit && region === 'PAKISTAN'">
      <EditBonus
        ref="editBonusRef"
        @editedBonus="editedBonus"
        :bonusToEdit="bonusToEdit"
      />
    </template>
    <template v-if="newBonus && region === 'PAKISTAN'">
      <NewBonus ref="newBonusRef" @addedNewBonus="addedNewBonus" />
    </template>
    <template v-if="region === 'US'">
      <EditBonus
        ref="editBonusRef"
        @editedBonus="editedBonus"
        :bonusToEdit="bonusToEdit"
      />
      <NewBonus ref="newBonusRef" @addedNewBonus="addedNewBonus" />
    </template>
  </div>
</template>

<script>
import Levels from "@/view/pages/bonus-levels/Levels";
import ArchivedLevels from "@/view/pages/bonus-levels/ArchivedLevels";
import Bonuses from "@/view/pages/bonus-levels/Bonuses";
import NewLevel from "@/view/pages/bonus-levels/NewLevel";
import NewLevelUs from "@/view/pages/bonus-levels/us/NewLevel";
import EditLevel from "@/view/pages/bonus-levels/EditLevel";
import NewBonus from "@/view/pages/bonus-levels/NewBonus";
import EditBonus from "@/view/pages/bonus-levels/EditBonus";
import { mapState } from "vuex";

export default {
  components: {
    NewLevelUs,
    Levels,
    ArchivedLevels,
    Bonuses,
    NewLevel,
    NewBonus,
    EditLevel,
    EditBonus
  },
  data() {
    return {
      activeTab: 0,
      levelToEdit: null,
      bonusToEdit: null,
      newBonus: false,
      newLevel: false
    };
  },

  watch: {
    bonusToEdit: function(x) {
      if (this.region === "US") {
        if (x !== null) this.$refs.editBonusRef.showModal();
      }
    }
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },
  methods: {
    changeTab: function(index) {
      this.activeTab = index;
    },
    newLevelBonusClick: function() {
      if (this.region === "PAKISTAN") {
        if (this.activeTab === 0) {
          this.newLevel = true;
          setTimeout(() => {
            this.$refs.newLevelRef.showModal();
          }, 100);
        } else if (this.activeTab === 2) {
          this.newBonus = true;
          setTimeout(() => {
            this.$refs.newBonusRef.showModal();
          }, 100);
        }
      }
      if (this.region === "US") {
        if (this.activeTab === 0) {
          this.$refs.newLevelRef.showModal();
        } else if (this.activeTab === 2) {
          this.$refs.newBonusRef.showModal();
        }
      }
    },
    addedNewLevel: function() {
      this.$refs.levelsRef.update();
      this.$refs.newLevelRef.closeModal();
      if (this.region === "PAKISTAN") {
        setTimeout(() => {
          this.newLevel = false;
        }, 100);
      }
    },
    editedLevel: function() {
      this.$refs.levelsRef.update();
      this.$refs.editLevelRef.closeModal();
      if (this.region === "PAKISTAN") {
        setTimeout(() => {
          this.levelToEdit = null;
        }, 100);
      }
    },
    editedBonus: function() {
      this.$refs.bonusesRef.update();
      this.$refs.editBonusRef.closeModal();
      if (this.region === "PAKISTAN") {
        setTimeout(() => {
          this.bonusToEdit = null;
        }, 100);
      }
    },
    addedNewBonus: function() {
      this.$refs.bonusesRef.update();
      this.$refs.newBonusRef.closeModal();
      if (this.region === "PAKISTAN") {
        setTimeout(() => {
          this.newBonus = false;
        }, 100);
      }
    },
    editLevel: function(level) {
      this.levelToEdit = level;
      if (this.region === "PAKISTAN") {
        setTimeout(() => {
          this.$refs.editLevelRef.showModal();
        }, 100);
      }
    },
    closeEditLevel: function() {
      if (this.region === "PAKISTAN") {
        this.$refs.editBonusRef.closeModal();
      }
      setTimeout(() => {
        this.levelToEdit = null;
      }, 100);
    },
    editBonus: function(bonus) {
      console.log("bonus", bonus);
      this.bonusToEdit = bonus;
      if (this.region === "PAKISTAN")
        setTimeout(() => {
          this.$refs.editBonusRef.showModal();
        }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.level-bonuses {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;

    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
